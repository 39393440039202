/*--------------------------------------------------------------
# Import des fichiers CSS nécessaires
--------------------------------------------------------------*/
@import 'src/assets/styles/_variables.css';

/*--------------------------------------------------------------
# Styles globaux
--------------------------------------------------------------*/
/* Définition de la taille de police par défaut en fonction de la taille de l'écran */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

@media screen and (min-width: 768px) {
  html {
    font-size: 18px;
  }
}

/* Style de base pour le corps du document */
body {
  font-size: 1rem;
  color: var(--gray-800);
  margin: 0;
  padding-bottom: 80px;
  box-sizing: border-box;
}

/* Style global pour l'en-tête */
header {
  background: #747E88!important;
}

a {
  color: var(--greensecondary);
}

a:hover {
  color: var(--autregreen);
}

/* Espacement des sections */
section {
  padding-top: 1vh;
  padding-bottom: 1vh;
}

/* Style global pour les petits textes */
small {
  color: var(--mediumGrey);
}

/*--------------------------------------------------------------
# Boutons personnalisés
--------------------------------------------------------------*/
/* Style pour les boutons principaux (bleu) */
.btn-primary-bib {
  border-radius: 60px;
  background: var(--primaryColour);
  color: white;
  padding: 10px 20px;
}

.btn-primary-bib:hover {
  background: var(--darkgreen);
}

/* Style pour les boutons d'alerte (rouge) */
.btn-danger-bib {
  border-radius: 60px;
  background: var(--errorRed);
  color: white;
}

.btn-danger-bib:hover {
  background: #e74904;
}

/* Style pour les boutons gris */
.btn-gray-bib {
  border-radius: 60px;
  background: var(--gray-700);
  color: white;
  padding: 10px 20px;
}

.btn-gray-bib:hover {
  background: #494949;
}

/* Style pour les boutons bleu foncé */
.btn-login-bib {
  border-radius: 60px;
  background: var(--gray-600);
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--lightgreen);
}

.btn-login-bib:hover {
  background: var(--gray-800);
}

/*--------------------------------------------------------------
# Boutons transparents avec bordures colorées
--------------------------------------------------------------*/
/* Bouton transparent avec bordure et texte bleu */
.btn-b-transparent-bib {
  border-radius: 60px;
  background: transparent;
  border-color: var(--primaryColour);
  color: var(--primaryColour);
  padding: 10px 20px;
}

.btn-b-transparent-bib:hover {
  background: var(--primaryColour);
  color: var(--lightGrey);
}

/* Bouton transparent avec bordure et texte bleu */
.btn-alert-transparent-bib {
  border-radius: 60px;
  background: transparent;
  border-color: var(--errorRed);
  color: var(--errorRed);
  padding: 10px 20px;
}

.btn-alert-transparent-bib:hover {
  background: var(--errorRed);
  color: var(--lightGrey);
}

/* Bouton transparent avec bordure et texte blanc */
.btn-w-transparent-bib {
  border-radius: 60px;
  background: transparent;
  border-color: #e8e8e8;
  color: var(--lightGrey);
  padding: 10px 20px;
}

.btn-w-transparent-bib:hover {
  background: #e8e8e8;
  color: var(--primaryColour);
}

/* Bouton transparent avec bordure et texte blanc */
.btn-g-transparent-bib {
  border-radius: 60px;
  background: transparent;
  border-color: #dee2e6;
  color: #dee2e6;
  padding: 10px 20px;
}

.btn-g-transparent-bib:hover {
  background: #dee2e6;
  color: var(--darkgreen);
}

/* Style pour les boutons principaux (yellow) */
.btn-y-transparent-bib {
  border-radius: 60px;
  background: transparent;
  border-color: var(--jaune);
  color: var(--jaune);
  font-family: 'Lora', sans-serif;
}

.btn-y-transparent-bib:hover {
  background: var(--jaune);
  color: white;
}
/*--------------------------------------------------------------
# Styles pour les titres et les textes
--------------------------------------------------------------*/
/* Définir la police Lora pour tous les titres h1 à h6 */
h1, h2, h3, h4, h5, h6 {
  color: var(--darkgreen);
  font-variant-numeric: lining-nums tabular-nums;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Lora', sans-serif;
  font-style: normal;
  line-height: 120%;
}

/* Style spécifique pour les titres h5 */
h5 {
  font-size: 20px;
  font-weight: 500;
}

/* Utiliser la police Figtree pour les paragraphes et autres textes */
p, span, small {
  color: var(--darkGrey);
  font-family: 'Figtree', sans-serif;
}

/*--------------------------------------------------------------
# Classes pour les tailles de police
--------------------------------------------------------------*/
.font-10 {
  font-size: 10px;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-48 {
  font-size: 48px;
}

/*--------------------------------------------------------------
# Couleurs du texte
--------------------------------------------------------------*/
.text-grey {
  color: var(--darkGrey);
}

.text-mgrey {
  color: var(--mediumGrey);
}

.text-primary {
  color: var(--primaryColour);
}

.text-icon {
  color: var(--primaryColour);
}

.text-jaune {
  color: var(--jaune);
}
.text-alert {
  color: var(--errorRed);
}

.text-lblue {
  color: var(--ligthBlue);
}

/*--------------------------------------------------------------
# Autres styles divers
--------------------------------------------------------------*/
/* Largeur de 20% */
.w-20 {
  width: 20%;
}

/* Fond bleu foncé pour les éléments */
.bg-darkBlue {
  background: var(--darkgreen);
}

/* Espacement et style pour les menus */
.toggle-menu-bib {
  padding: 25px;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  border-radius: 12px;
  background: var(--lightGrey);
  font-size: 14px;
}

/* Style pour le bouton avec SVG à l'intérieur */
.btn-b-transparent-bib svg {
  transition: transform 0.3s ease, fill 0.3s ease;
}

.btn-b-transparent-bib:hover svg path {
  fill: #ffffff;
}

.btn-b-transparent-bib:hover svg {
  transform: scale(1.3);
}


.btn-bib {
  padding: 5px 10px;
}
/*--------------------------------------------------------------
# Styles spécifiques aux pages
--------------------------------------------------------------*/
/* Styles pour la page de connexion */
.custom-section {
  background-color: #ffffff;
  min-height: 70vh;
  margin-top: 2.5rem;
}

.custom-image {
  border-radius: 16px;
  object-fit: cover;
  width: 700px;
}

.text-content {
  padding: 40px;
}

.custom-title {
  font-family: 'Figtree', sans-serif;
  color: var(--greensecondary);
  font-size: 2.4rem;
  font-weight: 600;
}

/* Media query pour les écrans de taille moyenne  */
@media (max-width: 768px) {
  .custom-title {
    font-size: 2.2rem;
  }
}
/* Media query pour les écrans tablettes */
@media (max-width: 768px) {
  .custom-title {
    font-size: 2rem;
  }
}

/* Media query pour les écrans plus petits (smartphones, par exemple) */
@media (max-width: 480px) {
  .custom-title {
    font-size: 1.8rem;
  }
}

.custom-description {
  color: var(--gray-700);
  margin-bottom: 20px;
  font-size: 1rem;
  line-height: 1.5;
  text-align: start;
  margin-top: 10px;
}

/* Styles pour la page 404 */
.not-found-section {
  padding: 40px 0;
  background-color: #f9f9f9;
}

.not-found-title {
  font-size: 38px;
  color: var(--greensecondary);
  margin-bottom: 20px;
}

/* ---------- Styles pour le footer ---------- */
.link-footer a {
  color: var(--extralightgreen)!important;
  text-decoration: none!important;
}

.link-footer p {
  color:var(--mediumGrey)!important;
  text-decoration: none!important;
}

.link-footer a:hover {
  color: var(--lightgreen)!important;
}
.link-emergency{
  color: white!important;
}

/* Style pour le bouton navbar */
.navbar-dark .navbar-toggler {
  color: rgba(238, 255, 255, 0.8) !important;
  border-color: rgba(238, 255, 255, 0.3);
  background: transparent;
  border: none;
  padding: 5px;
}

/* Sélecteur de langue */
.language-selector {
  font-family: 'Lora', sans-serif;
  background: transparent;
  border: 1px solid #eef4f7;
  color: #eef4f7;
  outline: none;
  font-size: 16px;
  margin-left: 10px;
  border-radius: 60px;
}

.language-selector option {
  background: var(--greensecondary);
  color: #eef4f7;
  font-size: 16px;
  padding: 5px 10px;
  border-radius: 12px;
}

/* Section droite du menu (icône utilisateur, etc.) */
.menu-right {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.menu-right .fas.fa-user {
  color: var(--lightgreen);
  font-size: 14px;
}

.bib-navbar {
  border-bottom: var(--green) 2px solid;
}

/* ---------- Styles Responsive pour le Header ---------- */

@media (max-width: 576px) {
  .logo-text {
    font-size: 22px;
    margin-top: 30px;
  }

  ul.menu-items li a {
    font-size: 11px;
  }

  .language-selector {
    font-size: 14px;
  }

  .navbar-dark .navbar-toggler {
    padding: 4px;
  }

  .navbar-brand {
    width: 50%;
  }

  .logo-text {
    font-size: 14px!important;
    margin-top: 20px;
  }

  .menu-right {
    padding: 15px;
  }

  .menu-right .fas.fa-user {
    font-size: 14px;
  }

  .custom-image {
    margin-left:10px;
  }
}
/* ---------- Styles pour le Footer ---------- */



/* Icônes des réseaux sociaux à droite */
.social-icons {
  display: flex;
  gap: 15px;
}

.social-icons img {
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.social-icons img:hover {
  transform: scale(1.1);
}

.card {
  border: 1px solid rgb(238, 244, 247);
  background: #EEF4F7;
}
.hand-cursor {
  cursor: pointer;
}
